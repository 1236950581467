<template>
  <div class="bgimg">
    <div class="middle">
      <h1>PAGE NOT FOUND</h1>
      <hr>
    </div>
  </div>
</template>

<script>
export default {
  name: "404"
}
</script>

<style scoped>
/* Set height to 100% for body and html to enable the background image to cover the whole page: */
body, html {
  height: 900px !important
}

.bgimg {
  /* Background image */
  /* Full-screen */
  height: 900px !important;
  /* Center the background image */
  background-position: center;
  /* Scale and zoom in the image */
  background-size: cover;
  /* Add position: relative to enable absolutely positioned elements inside the image (place text) */
  position: relative;
  /* Add a white text color to all elements inside the .bgimg container */
  color: white;
  /* Add a font */
  font-family: "Courier New", Courier, monospace;
  /* Set the font-size to 25 pixels */
  font-size: 25px;
}

/* Position text in the top-left corner */
.topleft {
  position: absolute;
  top: 0;
  left: 16px;
}

/* Position text in the bottom-left corner */
.bottomleft {
  position: absolute;
  bottom: 0;
  left: 16px;
}

/* Position text in the middle */
.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

/* Style the <hr> element */
hr {
  margin: auto;
  width: 40%;
}
</style>
